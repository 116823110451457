import React, { useEffect } from 'react';

import Button from '../../components/Button';

import { useFeathers } from '../../FeathersProvider';

import { connect } from "react-redux";

const logo = require('../../assets/logo_drgaucher.png');

const Login = (props) => {

  const feathers = useFeathers();

  useEffect(() => {
    const token = window.location.hash.split('#access_token=').filter((p) => p !== "")[0];
    if (token) {
      feathers.authenticate(token).then(r => {
        alert(JSON.stringify(r))
      }).catch(e => {
        alert(JSON.stringify(e))
      });
    }
  }, []);

  return (
    <div className='screen login flex flex-col grow'>
      <img className='logo mx-auto mt-9' src={logo} alt="Dr. Gaucher" />
      <div className='form mx-auto mt-10 mb-20 flex flex-col justify-between items-center grow'>
        <div className='separator' />
        {/* <Button to={`gamechoice`} className='btn-primary'>Entrar</Button>
        <a
          href="http://localhost:3030/oauth/google"
          className='btn-primary'
        >
          Google
        </a> */}
        <a
          href={`${process.env.REACT_APP_API_URL}/oauth/okta`}
          className='btn-primary'
        >
          Acceder con Takeda ID
        </a>
      </div>
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest,
    app: state.services.app
  }), null
)(Login);
