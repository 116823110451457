import React, {} from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../services/app/actions';

const Modal = (props) => {

  return (
    <React.Fragment>
      <div onClick={(e) => {
        if (e.target.classList.contains('backdrop')) props.actions.app.update({ modal: { visible: false } });
      }} className={`backdrop ${props.modal.visible ? null : '_hidden'}`}>

      </div>
      <div className={`customModal ${props.modal.visible ? null : '_hidden'}`}>
        <div className='close' onClick={() => props.actions.app.update({ modal: { visible: false } })}></div>
        <div className='content flex' style={ props.modal.customBackground ? { backgroundImage: `url(${props.modal.customBackground})` } : {}}>
          {props.modal.source ?
            <iframe
              title="Intro"
              className='grow'
              src={props.modal.source}
              //onLoad={ () => setLoading(false) }
            />
          : null}
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect(
  (state, ownProps) => ({
    modal: state.services.app.modal
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Modal);