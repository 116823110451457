import React, { useEffect } from 'react';

import Button from '../../components/Button';

import { useFeathers } from '../../FeathersProvider';

import { connect } from "react-redux";

const logo = require('../../assets/logo_drgaucher.png');

const GameChoice = (props) => {

  const feathers = useFeathers();

  const games = props.manifest.games.map(g => ({
    id: g.id,
    name: g.name,
    firstItem: g.welcome[0]
  }));

  return (
    <div className='screen login flex flex-col items-center grow'>
      <img className='logo mx-auto mt-9' src={logo} alt="Dr. Gaucher" />
      <div className='form max-w-screen-md mt-4 mb-20 flex flex-col justify-between items-stretch grow'>
        <iframe className='flex-1' src={props.manifest.choiceContent} />
        <div className='flex justify-center mt-4'>
          {games.map((g,i) => (
            <Button to={`/${g.id}/welcome/${g.firstItem.type}/${g.firstItem.id}`} className={`btn-primary ${games.length > i + 1 ? 'mr-2' : null}`}>{g.name}</Button>
          ))}
        </div>


        {/*<a
          href="http://localhost:3030/oauth/google"
          className='btn-primary'
        >
          Entrar
        </a>*/}
      </div>
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest
  }), null
)(GameChoice);
