import React, { useEffect } from "react";

import { useNavigate, useParams } from  'react-router-dom';
import Button from "../../../../../components/Button";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../../../../services/app/actions';

import dayjs from "dayjs";
import { useFeathers } from "../../../../../FeathersProvider";

const Intro = props => {

  const feathers = useFeathers();

  const navigate = useNavigate();

  const { gameId } = useParams();

  const { setLoading, game, clinicalCase, caseIndex } = props;

  const { iframe } = clinicalCase;

  useEffect(() => {
    setLoading(true);
  }, []);

  const startGame = () => {
    // Set the game end time and code length
    const endTime = dayjs().second(parseInt(dayjs().second())).add(game.duration, 'seconds');
    const code = game.cases.reduce((prev, cur) => {
      return [...prev, ...cur.blocks.map(b => null)];
    }, []);
    feathers.create("gameplays", {
      gameId,
      endTime,
      code,
      score: 0
    }).then(r => {
      props.actions.app.update({ currentGame: r });
      navigate(`block/${clinicalCase.blocks[0].id}`)
    });
  };

  return (
    <React.Fragment>
      <iframe
        title="Intro"
        className='body grow my-12 mx-auto w-full max-w-screen-lg mb-12'
        src={iframe}
        onLoad={ () => setLoading(false) }
      />
      <div className='footer flex shrink-0 justify-center mb-6'>
        <Button
          className='btn-primary'
          music={clinicalCase.blocks[0].music}
          onClick={() => {
            // If it's the first case
            if (caseIndex === 0) {
              startGame()
              // // Set the game end time and code length
              // const endTime = dayjs().second(parseInt(dayjs().second())).add(game.duration , 'seconds');
              // const code = game.cases.reduce((prev, cur) => {
              //   return [...prev, ...cur.blocks.map(b => null)];
              // }, []);

              // props.actions.app.update({ currentGame: { endTime, code } });
            } else {
              navigate(`block/${clinicalCase.blocks[0].id}`)
            }

          }}
        >Iniciar prueba</Button>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame,
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Intro);