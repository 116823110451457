import React, { useEffect, useState } from "react";

import Button from '../../../../components/Button';
import Window from "../../../../components/Window";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../../../services/app/actions';

import { useNavigate, useParams } from  'react-router-dom';
import { useFeathers } from "../../../../FeathersProvider";

const HtmlToReactParser = require("html-to-react").Parser;

const logo = require('../../../../assets/logo_drgaucher.png');

const addLeadingZero = (value) => {
  if (value < 10) {
    return `0${value}`;
  } else {
    return `${value}`;
  }
}


const Ranking = props => {

  const navigate = useNavigate();
  const feathers = useFeathers();

  const [loading, setLoading] = useState(false);
  const [ranking, setRanking] = useState([]);


  const { gameId } = useParams();
  const game = props.manifest.games.find(g => g.id == gameId);

  useEffect(() => {
    setLoading(true);
    feathers.get("rankings", gameId).then(r => {
      setRanking(r)
      setLoading(false)
    })
  }, [gameId]);


  return (
    <div className='screen welcome flex flex-col grow'>
      <img className='logo mx-auto my-auto' src={logo} alt="Dr. Gaucher" />
      <Window dark loading={loading}>
        <div className="border-b border-b-[#CBCBCB] flex flex-col items-stretch">
          <table class="table-auto mx-20 mr-[92px]">
            <thead className="">
              <tr className="">
                <th width="100" className="uppercase font-light text-xs py-2">Posición</th>
                <th className="text-left uppercase font-light text-xs">Nombre</th>
                <th width="100" className="text-left uppercase font-light text-xs">Puntuación</th>
                <th width="100" className="text-left uppercase font-light text-xs">Tiempo</th>
              </tr>
            </thead>
          </table>
        </div>

        <div className="flex flex-1 flex-col items-stretch px-10 mx-10 my-2 overflow-y-scroll">
          <table class="table-auto">
            <tbody className=" overflow-y-scroll scrollbar-reserve">
              {ranking.map((item, key) => {

                const totalSeconds = Math.round(item.finalTime / 1000);
                const remainingMinutes = Math.floor(totalSeconds/60);
                const remainingSeconds = totalSeconds % 60;

                return (
                  <tr key={`ranking_${key}`} className={`table-row ${item.user._id == props.feathersUser._id && "bg-white/20"}`}>
                    <td width="100" className="py-1.5 text-center uppercase font-bold border-b border-b-[#CBCBCB]/20">{key + 1}</td>
                    <td className="font-bold border-b border-b-[#CBCBCB]/20">{item.user.firstName} {item.user.lastName}</td>
                    <td width="100" className="uppercase font-bold border-b border-b-[#CBCBCB]/20">{item.score}</td>
                    <td width="100" className="uppercase font-bold border-b border-b-[#CBCBCB]/20">{addLeadingZero(remainingMinutes)}:{addLeadingZero(remainingSeconds)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>


        </div>
        <div className='footer flex shrink-0 justify-center'>
          <Button
            className='btn-secondary -translate-y-5'
            onClick={() => navigate(`/`)}
          >Volver a empezar</Button>
        </div>
      </Window>
    </div>
  );
};

export default connect(
  (state, ownProps) => ({
    modal: state.services.app.modal,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame,
    feathersUser: state.services.app.feathersUser,
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Ranking);