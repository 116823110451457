import { persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { reducer as appReducer } from './app/reducer';

const config = {
  key: "services",
  storage: storage
};

export const reducer = persistCombineReducers(config, {
  app: appReducer
});
