import React, {  } from 'react';

import Quiz from './scenes/Quiz';
import Ko from './scenes/Ko';

import {
  useParams,
  Routes,
  Route
} from "react-router-dom";


import { connect } from "react-redux";


const Welcome = (props) => {

  const { clinicalCase, block, setLoading, codeIndex } = props;

  const { questionId } = useParams();

  const question = block.questions.find(q => q.id === questionId);

  const questionIndex = block.questions.findIndex(q => q.id === questionId);

  return (
    <Routes>
      <Route path="/" element={<Quiz block={block} question={question} codeIndex={codeIndex} blockIndex={props.blockIndex} questionIndex={questionIndex} setLoading={setLoading} />} />
      <Route path="ko" element={<Ko clinicalCase={clinicalCase} block={block} question={question} blockIndex={props.blockIndex} setLoading={setLoading} />} />
    </Routes>
  );
}

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest
  }), null
)(Welcome);

