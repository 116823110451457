import React from 'react';

const ResponsiveCap = (props) => {

  return (
    <div className='bg-gray-900 absolute inset-0 z-40 flex justify-center items-center xl:hidden'>
      <p className='text-white text-xl font-bold'>Solo disponible para escritorio</p>
    </div>
  );
}

export default ResponsiveCap;