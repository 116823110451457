import React from "react";

const Window = props => {

  const { children, loading, dark } = props;

  return (
    <div className={`window ${dark ? 'dark-blur' : 'white-blur'} flex flex-col align-stretch ${loading ? 'loading' : ''}`} >
      <div className='header shrink-0'>
        {props.headerExtra}
      </div>

      {children}

    </div>
  );
};

export const Body = props => {

  const { children } = props;

  return (
    <div className='body grow'>
      {children}
    </div>
  );
};

export default Window;