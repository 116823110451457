// Imports: Dependencies
import { createStore, applyMiddleware } from "redux";
//import { createLogger } from "redux-logger";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";


import { reducer as servicesReducer } from "./services/reducer";



// Middleware: Redux Persist Config
const persistConfig = {
  // Root
  key: "drgaucher",
  // Storage Method (React Native)
  storage: storage,
  // Whitelist (Save Specific Reducers)
  whitelist: [],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: []
};
// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistCombineReducers(persistConfig, {
  services: servicesReducer,
  //data: dataReducer
});
// Redux: Devtools
const enhancer = applyMiddleware(thunk);
// Redux: Store
const store = createStore(persistedReducer, enhancer);
// Middleware: Redux Persist Persister
let persistor = persistStore(store);
// Exports
export { store, persistor };
