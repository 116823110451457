import React, {} from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../services/app/actions';
import Button from '../Button';

import dayjs from 'dayjs';

const Modal = (props) => {

  return (
    <React.Fragment>
      <div onClick={(e) => {
        if (e.target.classList.contains('backdrop')) props.actions.app.update({ rightModal: { visible: false } });
      }} className={`backdrop bg-transparent ${props.rightModal.visible ? null : '_hidden'}`}>

      </div>
      <div className={`rightModal ${props.rightModal.dark ? 'dark-blur' : 'white-blur'} flex flex-col ${props.rightModal.visible ? null : '_hidden'}`}>
        <div className='close' onClick={() => props.actions.app.update({ rightModal: { visible: false } })}></div>

        {props.rightModal.tip ?
          <div className='px-20 grow flex flex-col justify-center items-center'>
            <h4 className='text-3xl text-white font-bold text-center'>¿No estás seguro de la respuesta correcta? Acceder a la pista te costará 3 minutos. ¿Estás seguro?</h4>
            <Button className="btn-secondary mt-10" onClick={() => {
              const endTime = dayjs(props.currentGame.endTime).subtract(180, 'seconds');
              props.actions.app.update({
                modal: {
                  visible: true,
                  source: props.rightModal.url
                },
                rightModal: { visible: false },
                currentGame: { ...props.currentGame, endTime }
              });
            }}>Ver pista</Button>
          </div>
        :
        <div className='p-20 grow flex flex-col items-stretch'>
          <iframe
            title="Study"
            className='grow'
            src={props.rightModal.url}
            //onLoad={ () => setLoading(false) }
          />
        </div>
        }


      </div>
    </React.Fragment>
  );
}

export default connect(
  (state, ownProps) => ({
    rightModal: state.services.app.rightModal,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Modal);