import React, { useEffect } from 'react';


import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useFeathers } from '../../FeathersProvider';
import * as appActionCreators from '../../services/app/actions';

import content from '../../manifest.json';
import { useNavigate, useParams } from 'react-router-dom';

const events = require('events');

const GameController = props => {

  const feathers = useFeathers();

  const navigate = useNavigate();

  const gameplayLoaded = async (gameplayId) => {
    const gameplay = await feathers.get("gameplays", gameplayId);
    props.actions.app.update({ currentGame: gameplay });
    const stepIndex = gameplay.code.indexOf(null);
    const gameId = gameplay.gameId;
    const gameIndex = content.games.findIndex(g => g.id === gameId);
    let countedIndex = 0;
    for (let j = 0; j < content.games[gameIndex].cases.length; j++) {
      for (let k = 0; k < content.games[gameIndex].cases[j].blocks.length; k++) {
        if (countedIndex == stepIndex) {
          navigate(`/${gameId}/game/case/${content.games[gameIndex].cases[j].id}/block/${content.games[gameIndex].cases[j].blocks[k].id}`)
        }
        countedIndex++;
      }
    }
  };

  useEffect(() => {
    feathers.em.on("gameplayLoaded", gameplayLoaded);
    if (feathers.pendingGameplayLoaded.current) {
      gameplayLoaded(feathers.pendingGameplayLoaded.current);
      feathers.pendingGameplayLoaded.current = false;
    }

    return () => {
      feathers.em.off("gameplayLoaded", gameplayLoaded);

    };
  }, [feathers]);

  return null;

};

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame,
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(GameController);