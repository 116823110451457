import React, { useEffect, useState, useRef } from "react";
import Button from "../../../../components/Button";

import { useNavigate, useParams } from  'react-router-dom';

const Video = props => {

  const navigate = useNavigate();

  const [isVisible, setIsVisible] = useState(false);

  const { id, gameId } = useParams();

  const videoRef = useRef(null);

  const { setLoading, welcome, cases } = props;

  const item = welcome.find(i => i.id == id);

  const index = welcome.findIndex(i => i.id == id);

  const next = welcome.length > index + 1 ? `/${gameId}/welcome/${welcome[index + 1].type}/${welcome[index + 1].id}` : `/${gameId}/game/case/${cases[0].id}`;

  useEffect(() => {
    setLoading(false);
  }, [id]);

  useEffect(() => {
    if (isVisible && videoRef.current) videoRef.current.play();
  }, [isVisible])



  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      }, { rootMargin: '0px' }
    );

    videoRef.current && observer.observe(videoRef.current);

    return () => observer.unobserve(videoRef.current);
  }, []);

  return (
     <React.Fragment>
      <div className='body grow bg-black'>
        <video
          // autoPlay
          src={item.src}
          ref={videoRef}
          onPlay={() => {
            //alert("play")
          }}
          onEnded={() => {
            setLoading(true);
            setTimeout(() => {
              navigate(next)
            }, 400);
          }}
          className=' '
          style={{ objectFit: 'contain' }}
        />
      </div>
      <div className='footer flex justify-center'>
        <Button
          className='btn-secondary -translate-y-5 mx-auto'
          onClick={() => {
            setLoading(true);
            setTimeout(() => {
              navigate(next)
            }, 400);
          }}
        >Saltar video</Button>
      </div>
    </React.Fragment>
  );
};

export default Video;