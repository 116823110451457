import React, { useEffect } from "react";

import { useNavigate, useParams } from  'react-router-dom';
import Button from "../../../../../../../../../components/Button";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../../../../../../../../services/app/actions';
import { useFeathers } from "../../../../../../../../../FeathersProvider";

const Ok = props => {

  const feathers = useFeathers();

  const navigate = useNavigate();

  const { gameId, blockId, questionId } = useParams();


  const { setLoading, clinicalCase } = props;


  useEffect(() => {
    setLoading(true);
  }, []);

  const block = clinicalCase.blocks.find(b => b.id === blockId);
  const question = block.questions.find(q => q.id === questionId);
  const index = block.questions.findIndex(q => q.id == questionId);

  let next = '/';

  const game = props.manifest.games.find(g => g.id == gameId);
  const caseIndex = game.cases.findIndex(cc => cc.id == clinicalCase.id);
  const blockIndex = clinicalCase.blocks.findIndex(b => b.id == blockId);



  return (
    <React.Fragment>
      <iframe
        title="Intro"
        className='body grow my-12 mx-auto w-full max-w-screen-lg mb-12'
        src={question.ok}
        onLoad={ () => setLoading(false) }
      />
      <div className='footer flex shrink-0 justify-center'>
        <Button
          className='btn-primary mb-4'
          onClick={async () => {
            if (block.questions.length > index + 1) { // If more questions in this block
              next = `/${gameId}/game/case/${clinicalCase.id}/block/${block.id}/question/${block.questions[index+1].id}`;
            } else if (clinicalCase.blocks.length > blockIndex + 1) { // More blocks in this case
              next = `/${gameId}/game/case/${clinicalCase.id}/block/${block.id}/code`;
            } else if (game.cases.length > caseIndex + 1) { // No more blocks but another case
              next = `/${gameId}/game/case/${clinicalCase.id}/block/${block.id}/code`;
            } else { // No more blocks nor cases
              next = `/${gameId}/game/finalok`;

              const r = await feathers.patch("gameplays", props.currentGame._id, {
                code: props.currentGame.code,
                finalTime: new Date(props.currentGame.endTime).getTime() - new Date().getTime(),
                endTime: null,
                score: props.currentGame.score
              });
              props.actions.app.update({ currentGame: r });
            }
            navigate(next)
          }}
        >Continuar</Button>
        {question.study ?
          <Button
            className='btn-secondary mx-4'
            onClick={() => {
              props.actions.app.update({ rightModal: { visible: true, dark: true, tip: false, url: question.study } });
            }}
          >Estudio</Button>
        : null }
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame,
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Ok);