import React, { useState, useEffect, useRef } from 'react';

import {
  useParams,
  Outlet
} from "react-router-dom";

import Footer from '../../components/Footer';
import Menu from '../../components/Menu';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../services/app/actions';


import Modal from '../../components/Modal';
import CodeModal from '../../components/CodeModal';
import ReferencesModal from '../../components/ReferencesModal';
import RightModal from '../../components/RightModal';

import dayjs from 'dayjs';
import ResponsiveCap from '../../components/ResponsiveCap';
import AcronymsModal from '../../components/AcronymsModal';
import { useFeathers } from '../../FeathersProvider';
import GameController from '../../components/GameController';
import RightBar from '../../components/RightBar';
import LeftBar from '../../components/LeftBar';


const GameLayout = props => {

  const feathers = useFeathers();

  const [menuOpen, setMenuOpen] = useState(false);

  const { gameId, caseId, blockId } = useParams();

  const game = props.manifest.games.find(g => g.id == gameId);

  const clinicalCase = game.cases.find(c => c.id === caseId);

  const block = clinicalCase ? clinicalCase.blocks.find(b => b.id === blockId) : null;

  const background = block ? block.background : game.finalBackground;




  return (
    <div style={{height: '100vh'}} className='flex flex-col'>
      <div className="grow overflow-y-scroll question flex flex-row" style={{ backgroundImage: `url(${background})` }}>
        <LeftBar hasHeader />
        <div className='flex flex-col flex-1 shrink self-stretch'>
          <Outlet />
        </div>
        <RightBar game={game} noCounter={props.noCounter} />
        <Menu open={menuOpen} clinicalCase={clinicalCase} block={block} onOverlayClick={() => setMenuOpen(false)} />
      </div>
      <Footer />
      <Modal />
      <CodeModal />
      <ReferencesModal />
      <AcronymsModal />
      <RightModal />
      <ResponsiveCap />
      <GameController />
    </div>
  );
};

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame,
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(GameLayout);