import React, { useEffect } from "react";

import Button from '../../../../../../../components/Button';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../../../../../../services/app/actions';

import { useNavigate, useParams } from  'react-router-dom';

const Code = props => {

  const navigate = useNavigate();

  const { clinicalCase, blockIndex, question, setLoading } = props;

  const { gameId, blockId } = useParams();

  useEffect(() => {
    setLoading(false);
  }, []);

  const game = props.manifest.games.find(g => g.id == gameId);

  const globalBlockIndex = game.cases.flatMap(c => c.blocks).findIndex(b => b.id == blockId);

  const index = game.cases.flatMap(c => c.blocks).filter((b,i) => i <= globalBlockIndex ).flatMap(b => b.questions).length;

  const prettyIndex = index < 9 ? `0${index}` : `${index}`;

  return (
    <React.Fragment>
      <div className='body grow flex flex-row items-start overflow-y-scroll px-20 py-20'>
        <div class="w-1/5 font-bold border-l-4 px-3 text-white">
          <p className="text-4xl">Prueba</p>
          <p className="text-8xl">{prettyIndex}</p>
        </div>
        <div className="w-3/5 flex flex-col items-start px-10">
          <h2 className="text-5xl font-bold mb-4">¡Felicidades!</h2>
          <p className="">Has encontrado una de las letras del código secreto del Dr. Gaucher.
Consigue todas las letras para descubrir el mensaje oculto y salir de la isla antes de que se agote el tiempo.</p>
          <p className="mt-6 text-xs font-light uppercase">El fragmento de código es:</p>
          <div className="flex flex-row mt-2">
            {props.currentGame.code.map(i => (
              <div className="h-16 w-16 mr-2.5 flex justify-center items-center text-5xl font-bold border rounded-lg border-white">
                {i ? i
                  :
                  (
                    i === 0 ?
                    <div className="m-1 flex grow self-stretch justify-center items-center white-blur">
                      <img src="/semicross.svg" className="w-6" />
                    </div>
                    : null
                  )
                }
              </div>
            ))}
          </div>
        </div>
        <div className="w-1/5"></div>
      </div>
      <div className='footer flex shrink-0 justify-center'>
        <Button
          className='btn-secondary -translate-y-5'
          onClick={() => navigate('../outro')}
        >Siguiente</Button>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame,
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Code);