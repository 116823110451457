import React, { useEffect } from "react";

import Button from "../../../../components/Button";

import { useNavigate, useParams } from  'react-router-dom';

const Intro = props => {

  const navigate = useNavigate();

  const { id, gameId } = useParams();

  const { setLoading, welcome, cases } = props;

  const item = welcome.find(i => i.id == id);

  const index = welcome.findIndex(i => i.id == id);

  const next = welcome.length > index + 1 ? `/${gameId}/welcome/${welcome[index + 1].type}/${welcome[index + 1].id}` : `/${gameId}/game/case/${cases[0].id}`;


  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <React.Fragment>
      <iframe
        title="Intro"
        className='body grow m-12 mb-8'
        src={item.src}
        onLoad={ (evt) => {
          setLoading(false)
        } }
      />
      <div className='footer flex justify-center'>
        <Button
          className='btn-secondary -translate-y-5 mx-auto'
          onClick={() => {
            setLoading(true);
            setTimeout(() => {
              navigate(next)
            }, 400);
          }}
        >{item.next}</Button>
      </div>
    </React.Fragment>
  );
};

export default Intro;