import React, { useEffect, useState } from 'react';

import Window from '../../components/Window';

import Intro from './scenes/Intro';
import Video from './scenes/Video';

import { connect } from "react-redux";

import {
  Routes,
  Route,
  useParams
} from "react-router-dom";

const logo = require('../../assets/logo_drgaucher.png');

const Welcome = (props) => {

  const { gameId } = useParams();
  const game = props.manifest.games.find(g => g.id == gameId);

  const { cases, welcome } = game;

  const [loading, setLoading] = useState(true);

  return (
    <div className='screen welcome flex flex-col grow'>
      {/* <img className='logo mx-auto my-auto' src={logo} alt="Dr. Gaucher" /> */}
      <Window dark loading={loading}>
        <Routes>
          <Route path="intro/:id" element={<Intro welcome={welcome} cases={cases} setLoading={setLoading} />} />
          <Route path="video/:id" element={<Video welcome={welcome} cases={cases} setLoading={setLoading} />} />
        </Routes>
      </Window>
    </div>
  );
}

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest
  }), null
)(Welcome);

