import React, {} from 'react';

import { connect } from "react-redux";
import { useParams } from 'react-router-dom';
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../services/app/actions';
import Button from '../Button';

const Modal = (props) => {

  const { gameId } = useParams();
  const game = props.manifest.games.find(g => g.id == gameId);

  return (
    <React.Fragment>
      <div onClick={(e) => {
        if (e.target.classList.contains('backdrop')) props.actions.app.update({ acronymsModal: false });
      }} className={`backdrop bg-transparent ${props.acronymsModal ? null : '_hidden'}`}>

      </div>
      <div className={`referencesModal flex flex-col ${props.acronymsModal ? null : '_hidden'}`}>
        <div className='close' onClick={() => props.actions.app.update({ acronymsModal: false })}></div>
        <div className='pt-10 pb-6 mr-10 px-5 flex justify-center items-center'>
          <h2 className='text-3xl text-white font-bold text-center'>Acrónimos</h2>
        </div>
        <div className='mr-6 px-6 py-10 flex flex-col grow overflow-y-scroll'>
          {/*<Button className="btn-secondary mb-7" style={{ paddingTop: 30, paddingBottom: 30, fontSize: "0.7rem" }}>{r.title}</Button>*/}
          {game && game.acronyms.map(r => (
            <span className="reference p-4 rounded-lg">{r.title}</span>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect(
  (state, ownProps) => ({
    acronymsModal: state.services.app.acronymsModal,
    manifest: state.services.app.manifest
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Modal);