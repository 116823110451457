import React, { useState } from 'react';
import Timer from '../Timer';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../services/app/actions';
import { useFeathers } from '../../FeathersProvider';

const RightBar = props => {

  const feathers = useFeathers();

  const { game } = props;

  return (
    <React.Fragment>
      <div className='flex flex-col justify-start items-end rightbar py-4 px-8 w-40 shrink-0'>

        {props.feathersUser && props.feathersUser._id ?
          <div onClick={() => {
            if (window.confirm("¿Deseas cerrar sesión?")) {
              feathers.logOut();
            }
          }} className={`${props.blackOverlay ? 'dark-blur' : 'white-blur'} rounded-full p-1 flex justify-center items-center cursor-pointer`}>
            <svg className='w-[31px] h-[31px]' version="1.1" id="Layer_1" x="0px" y="0px"
              viewBox="0 0 31.3 31.3">
              <g id="Group_4">
                <path id="Path_4" className="st0 fill-white" d="M23.5,24.3c-0.3,0-0.5-0.2-0.5-0.5c0-4.1-3.3-7.3-7.3-7.3s-7.3,3.3-7.3,7.3c0,0,0,0,0,0
      c0,0.3-0.2,0.5-0.5,0.5c-0.3,0-0.5-0.2-0.5-0.5l0,0c0-4.6,3.7-8.4,8.4-8.4c4.6,0,8.4,3.7,8.4,8.4c0,0,0,0,0,0
      C24,24,23.8,24.3,23.5,24.3"/>
                <path id="Path_5" className="fill-white" d="M15.6,14.8c-2.2,0-3.9-1.8-3.9-3.9S13.5,7,15.6,7c2.2,0,3.9,1.8,3.9,3.9c0,0,0,0,0,0
      C19.6,13.1,17.8,14.8,15.6,14.8 M15.6,8c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9C18.5,9.3,17.2,8,15.6,8"/>
              </g>
            </svg>


          </div>
        : null}
        {game && !props.noCounter ?
          <div className={`mt-2 counter px-4 text-2xl font-light ${props.blackOverlay ? ' dark-blur' : 'white-blur'} flex justify-center items-center`}>
            <Timer default={game.duration} targetDate={props.currentGame.endTime} finalTime={props.currentGame.finalTime} />
          </div>
          : null}
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame,
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(RightBar);