import * as actionTypes from "./actionTypes";

export const initialState = {
  feathersConnected: false,
  feathersAuthenticated: false,
  feathersUser: {},
  manifest: {},
  currentGame: {
    endTime: null,
    code: [null, null, null, null, null, null],
    finalTime: 0
  },
  codeModal: false,
  referencesModal: false,
  acronymsModal: false,
  rightModal: {
    visible: false,
    tip: null,
    url: null
  },
  modal: {
    visible: false
  }
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE:
      return {
        ...state,
        ...action.app
      };
    default:
      return state;
  }
};
