import React, { useState } from 'react';

import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  useParams,
  Outlet
} from "react-router-dom";

import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import Modal from '../../components/Modal';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../services/app/actions';

import CodeModal from '../../components/CodeModal';
import ReferencesModal from '../../components/ReferencesModal';
import RightModal from '../../components/RightModal';
import ResponsiveCap from '../../components/ResponsiveCap';
import AcronymsModal from '../../components/AcronymsModal';
import GameController from '../../components/GameController';
import LeftBar from '../../components/LeftBar';
import RightBar from '../../components/RightBar';


const Layout = props => {

  const params = useParams();

  const { gameId } = useParams();
  const game = props.manifest.games.find(g => g.id == gameId);

  const id = params["*"] ? params["*"].split('/').pop() : null;

  const item = id ? game.welcome.find(i => i.id == id) : null;

  const background = item ? item.background : null;

  return (
    <div className='flex flex-col ' style={{ height: '100vh' }}>
      <div className="grow overflow-y-scroll papyrus-fixed" style={background ? { backgroundImage: `url(${background})` } : {}}>
        {background ? null :
          <React.Fragment>
            <div className='tl' />
            <div className='tr' />
            <div className='bl' />
            <div className='br' />
          </React.Fragment>
        }
        <div className='rest flex flex-row'>
          <LeftBar hasHeader={props.hasHeader} noProgress={props.noProgress} />
          <div className='flex flex-col flex-1 self-stretch'>
            <Outlet />
          </div>
          <RightBar blackOverlay game={game} noCounter={props.noCounter} />
        </div>
      </div>
      <Footer blackOverlay />
      <Modal />
      <CodeModal />
      <ReferencesModal />
      <AcronymsModal />
      <RightModal />
      <ResponsiveCap />
      {props.withGameContext && <GameController />}
    </div>
  );
};

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Layout);