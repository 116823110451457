import React, { useEffect, useState } from 'react';

import Window, { Body } from '../../../../../components/Window';

import Intro from './scenes/Intro';
import Question from './scenes/Question';
import Code from './scenes/Code';
import Outro from './scenes/Outro';

import {
  useParams,
  Routes,
  Route
} from "react-router-dom";


import { connect } from "react-redux";


const Welcome = (props) => {

  const { clinicalCase, setLoading } = props;

  const { caseId, blockId, gameId } = useParams();

  const game = props.manifest.games.find(g => g.id == gameId);
  const block = clinicalCase.blocks.find(b => b.id === blockId);
  const blockIndex = clinicalCase.blocks.findIndex(b => b.id === blockId);
  const caseIndex = game.cases.findIndex(c => c.id === caseId)
  let codeIndex = game.cases.slice(0, caseIndex).reduce((partialSum, a) => partialSum + a.blocks.length, 0) + blockIndex;


  console.log("Blocks", clinicalCase.blocks);
  console.log("Index", blockIndex);

  return (
    <Routes>
      <Route path="/" element={<Intro clinicalCase={clinicalCase} block={block} setLoading={setLoading} />} />
      <Route path="question/:questionId/*" element={<Question clinicalCase={clinicalCase} block={block} blockIndex={blockIndex} codeIndex={codeIndex} setLoading={setLoading} />} />
      <Route path="/code" element={<Code clinicalCase={clinicalCase} blockIndex={blockIndex} setLoading={setLoading} />} />
      <Route path="/outro" element={<Outro clinicalCase={clinicalCase} block={block} blockIndex={blockIndex} setLoading={setLoading} />} />
    </Routes>
  );
}

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest
  }), null
)(Welcome);

