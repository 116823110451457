import React, { useEffect, useState } from "react";

import Button from '../../../../components/Button';
import Window from "../../../../components/Window";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../../../services/app/actions';

import { useNavigate, useParams } from  'react-router-dom';

const HtmlToReactParser = require("html-to-react").Parser;

const logo = require('../../../../assets/logo_drgaucher.png');

const addLeadingZero = (value) => {
  if (value < 10) {
    return `0${value}`;
  } else {
    return `${value}`;
  }
}

const Final = props => {

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
  }, []);

  const { gameId } = useParams();
  const game = props.manifest.games.find(g => g.id == gameId);

  var htmlToReactParser = new HtmlToReactParser();
  var descriptionElement = game.finalContent && game.finalContent != '' ?
    htmlToReactParser.parse(game.finalContent) : null;

  const totalSeconds = Math.round(props.currentGame.finalTime / 1000);
  const remainingMinutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;



  return (
    <div className='screen welcome flex flex-col grow'>
      <Window dark loading={loading}>
        <div className="flex flex-col items-stretch grow px-6">

          <div className="mt-6 flex self-center">
            <div className="border border-white rounded mx-5 flex flex-col w-28">
              <p className="text-xs font-light uppercase px-2 py-2">CÓDIGOS</p>
              <div className="text-center text-2xl font-bold py-4 border-t border-slate-200">{props.currentGame.code.filter(i => i && i !== 0).length}/{props.currentGame.code.length}</div>
            </div>
            <div className="border border-white rounded mx-5 flex flex-col w-28">
              <p className="text-xs font-light uppercase px-2 py-2">Tiempo</p>
              <div className="text-center text-2xl font-bold py-4 border-t border-slate-200">{addLeadingZero(remainingMinutes)}:{addLeadingZero(remainingSeconds)}</div>
            </div>
          </div>

          <iframe
            title="Intro"
            className='body grow m-12 mb-8 mt-6'
            src={`${game.finalKo}?time=`+Date.now()}
            onLoad={ () => {
              setLoading(false)
            } }
          />
        </div>
        <div className='footer flex shrink-0 justify-center'>
          <Button
            stopMusic
            className='btn-secondary -translate-y-5'
            onClick={() => navigate(`/${game.id}/ranking`)}
          >Ir a ranking</Button>
        </div>
      </Window>
    </div>
  );
};

export default connect(
  (state, ownProps) => ({
    modal: state.services.app.modal,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame,
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Final);