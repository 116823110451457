import React, { useState } from 'react';
import Menu from '../Menu';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../services/app/actions';

const LeftBar = props => {

  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <React.Fragment>
      <div className='flex flex-col justify-start items-start leftbar py-8 px-8 w-40 shrink-0'>
        {props.hasHeader ?
          <React.Fragment>
            <div className='menu-buttons'>
              <button className='main bg-white drop-shadow-xl flex justify-center items-center' onClick={() => setMenuOpen(mo => !mo)}>
                <img src='/black-menu.svg' />
              </button>
              <button className='references bg-white flex justify-center items-center' onClick={() => props.actions.app.update({ referencesModal: true })}>
                <img src='/book-black.svg' />
              </button>
              <button className='acronyms bg-white flex justify-center items-center text-black' onClick={() => props.actions.app.update({ acronymsModal: true })}>
                ACR
              </button>
              <div className='mt-4 border border-white rounded-full px-1 py-3 dark-blur flex flex-col w-20 items-center self-center justify-self-end'>
                <p className='bg-white rounded-full w-10 h-10 flex justify-center items-center text-black'>i</p>
                <p className='text-xs mt-2 mb-3 text-center '>Supuesto clínico ficticio<br />con fines formativos</p>
              </div>
            </div>
            <div className=''>

            </div>

          </React.Fragment>
          : <div className='flex-1'></div>}

      </div>
      <Menu open={menuOpen} noProgress={props.noProgress} onOverlayClick={() => setMenuOpen(false)} />
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame,
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(LeftBar);