import React, { useEffect, useState } from "react";

import Button from '../../../../../../../../../components/Button';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../../../../../../../../services/app/actions';

import { useNavigate, useParams } from  'react-router-dom';
import { useFeathers } from "../../../../../../../../../FeathersProvider";

const Quiz = props => {

  const feathers = useFeathers();

  const navigate = useNavigate();

  const { block, blockIndex, question, questionIndex, setLoading, codeIndex } = props;

  const { gameId } = useParams();

  const [selectedAnswer, setSeletedAnswer] = useState(null);

  useEffect(() => {
    setLoading(false);
  }, []);

  const game = props.manifest.games.find(g => g.id == gameId);

  const index = game.cases.flatMap(c => c.blocks.flatMap(b => b.questions)).findIndex(q => q.id == question.id)

  const prettyIndex = index < 9 ? `0${index + 1}` : `${index +1}`;

  const sendAnswer = async () => {
    if (selectedAnswer) {
      const answer = question.options.find(o => o.id == selectedAnswer);
      if (answer) {
        if (answer.correct) {
          const hasNext = block.questions.length > questionIndex + 1;
          if (!hasNext) { // If it was also the last from the block
            const code = props.currentGame.code;
            code[codeIndex] = block.prize;
            const score = props.currentGame.score + 270;
            const gameplay = await feathers.patch("gameplays", props.currentGame._id, {
              code,
              score
            });
            props.actions.app.update({ currentGame: gameplay });
          }
          navigate("../ok")
        }
        else {
          const hasNext = block.questions.length > questionIndex + 1;
          if (!hasNext) { // If it was also the last from the block
            const code = props.currentGame.code;
            code[codeIndex] = 0;
            const score = props.currentGame.score - 5;
            const gameplay = await feathers.patch("gameplays", props.currentGame._id, {
              code,
              score
            });
            props.actions.app.update({ currentGame: gameplay });
          }
          navigate("../ko")
        }
      }
    }

  };

  return (
    <React.Fragment>
      <div className='body grow flex flex-row items-start overflow-y-scroll mx-20 my-20'>
        <div class="w-1/5 font-bold border-l-4 px-3 text-white">
          <p className="text-4xl">Prueba</p>
          <p className="text-8xl">{prettyIndex}</p>
        </div>
        <div className="w-3/5 flex flex-col items-center px-10">
          <h2 className="text-lg font-bold mb-10">{question.title}</h2>
          {question.options.map(o => (
            <Button onClick={() => setSeletedAnswer(o.id)} className={`w-full mt-5 py-2 px-4 rounded-3xl shadow-xl text-sm font-bold text-black uppercase ${o.id == selectedAnswer ? 'bg-white' : 'bg-white/60'}`} key={o.id}>{o.title}</Button>
          ))}
        </div>

      </div>
      <div className='footer flex shrink-0 justify-between'>
        <div className="flex-1">

        </div>
        <div className="flex flex-1 justify-center">
          <Button
            className='btn-secondary -translate-y-5'
            onClick={sendAnswer}
          >Confirmar respuesta</Button>
        </div>

        <div className="flex flex-1 justify-end">
          {question.tip ?
            <Button
              className='btn-secondary -translate-y-5 mr-20'
              onClick={() => {
                props.actions.app.update({ rightModal: { visible: true, tip: true, url: question.tip } });
              }}
            >Pista</Button>
          : null}
        </div>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame,
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Quiz);