import React from 'react';

import Button from '../Button';

import { useMatch } from  'react-router-dom';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../services/app/actions';


const Menu = props => {

  //const { clinicalCase, block } = props;

  const matched = useMatch("/:gameId/game/case/:caseId/block/:blockId*");
  const finalMatched = useMatch("/:gameId/*");

  let game = null;
  let clinicalCase = null;
  let block = null;

  if (matched && matched.params && matched.params.caseId && matched.params.blockId) {
    game = props.manifest.games.find(g => g.id == matched.params.gameId);
    clinicalCase = game.cases.find(c => c.id === matched.params.caseId)
    block = clinicalCase.blocks.find(b => b.id === matched.params.blockId)
  }

  if (!game && !clinicalCase && !block && finalMatched) { // Special case for final screens
    game = props.manifest.games.find(g => g.id == finalMatched.params.gameId);
    clinicalCase = game && game.cases ? game.cases.slice(-1)[0] : null;
    block = clinicalCase ? clinicalCase.blocks.slice(-1)[0] : null
  }

  return (
    <React.Fragment>
      <div className={`menu ${props.open ? '' : 'folded'}`}>
        <div className='wrap'>

          <Button disabled={props.noProgress} onClick={() => {
            if (block) {
              props.actions.app.update({ modal: { visible: true, customBackground: block.map } })
            }
          }} className="btn-secondary text-xs mb-2">Mi Progreso</Button>
          <Button disabled={props.noProgress} onClick={() => {
            if (clinicalCase) {
              props.actions.app.update({ modal: { visible: true, source: clinicalCase.iframe } })
            }
          }} className="btn-secondary text-sm mb-2">Supuesto clínico</Button>
          <Button disabled={props.noProgress} onClick={() => {
            props.actions.app.update({ codeModal: true })
          }} className="btn-secondary text-sm">Revisar código</Button>
        </div>
      </div>
      <div className={`menu-overlay ${props.open ? '' : 'folded'}`} onClick={props.onOverlayClick}></div>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    modal: state.services.app.modal,
    manifest: state.services.app.manifest
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Menu);