import React, {} from 'react';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../services/app/actions';

const Modal = (props) => {

  return (
    <React.Fragment>
      <div onClick={(e) => {
        if (e.target.classList.contains('backdrop')) props.actions.app.update({ codeModal: false });
      }} className={`backdrop bg-transparent ${props.codeModal ? null : '_hidden'}`}>

      </div>
      <div className={`codeModal flex flex-col py-6 justify-around items-stretch ${props.codeModal ? null : '_hidden'}`}>
        <div className='close' onClick={() => props.actions.app.update({ codeModal: false })}></div>
        <div className='flex items-center'>
          <div className='border-t border-white flex-1'></div>
          <h2 className='text-4xl font-bold mx-2'>Tu progreso ({props.currentGame.score})</h2>
          <div className='border-t border-white flex-1'></div>
        </div>
        <div className="flex flex-row self-center mt-2">
          {props.currentGame.code.map(i => (
            <div className="h-16 w-16 mr-2.5 flex justify-center items-center text-5xl font-bold border rounded-lg border-white">
              {i ? i
                :
                (
                  i === 0 ?
                  <div className="m-1 flex grow self-stretch justify-center items-center white-blur">
                    <img src="/semicross.svg" className="w-6" />
                  </div>
                  : null
                )
              }
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default connect(
  (state, ownProps) => ({
    codeModal: state.services.app.codeModal,
    currentGame: state.services.app.currentGame
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Modal);