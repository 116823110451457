import React, { useEffect, useState } from 'react';

import Window, { Body } from '../../../components/Window';

import MainLayout from '../../../Layouts/MainLayout';
import GameLayout from '../../../Layouts/GameLayout';

import Intro from './scenes/Intro';
import Block from './scenes/Block';
import Ok from './scenes/Block/scenes/Question/scenes/Ok';

import {
  useParams,
  Routes,
  Route,
  Outlet
} from "react-router-dom";

import { connect } from "react-redux";

const WindowLayout = (props) => {

  const { loading, clinicalCase } = props;

  return (
    <div className='screen welcome flex flex-col grow overflow-y-hidden'>
      <Window
        loading={loading}
        headerExtra={
          <div className='extra self-stretch pl-2 pr-10 flex flex-row justify-center items-center'>
            <p className='text-base font-light uppercase'>Supuesto clínico: </p>
            <p className='text-base font-bold ml-2'>{clinicalCase.title}</p>
          </div>
        }
      >
        <Outlet />
      </Window>
    </div>
  );
}

const Case = (props) => {

  const { caseId, blockId, questionId, gameId } = useParams();

  const game = props.manifest.games.find(g => g.id == gameId);
  const clinicalCase = game.cases.find(c => c.id === caseId);
  const caseIndex = game.cases.findIndex(c => c.id === caseId);


  const [loading, setLoading] = useState(true);

  return (
    <Routes>
      <Route element={<MainLayout withGameContext hasHeader noCounter noProgress />}>
        <Route path="/" element={<Intro game={game} clinicalCase={clinicalCase} caseIndex={caseIndex} setLoading={setLoading} />} />
      </Route>
      <Route element={<MainLayout withGameContext hasHeader />}>
        <Route path="block/:blockId/question/:questionId/ok" element={<Ok clinicalCase={clinicalCase} setLoading={setLoading} />} />
      </Route>
      <Route element={<GameLayout />}>
        <Route element={<WindowLayout loading={loading} clinicalCase={clinicalCase} />}>
          <Route path="block/:blockId/*" element={<Block clinicalCase={clinicalCase} setLoading={setLoading} />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest
  }), null
)(Case);

