import React, { useEffect, useRef } from 'react';

import { PersistGate } from 'redux-persist/integration/react'
import { Provider } from 'react-redux';

// Imports: Redux Persist Persister
import {store, persistor} from './store';

import {
  MemoryRouter,
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import FeathersProvider from './FeathersProvider';

import MainLayout from './Layouts/MainLayout';
import GameLayout from './Layouts/GameLayout';
import DenseLayout from './Layouts/DenseLayout';

import RequireAuth from './RequireAuth';
import Login from './scenes/Login';
import ComingSoon from './scenes/ComingSoon';

import GameChoice from './scenes/GameChoice';
import Welcome from './scenes/Welcome';

import Case from './scenes/Game/Case';
import FinalOk from './scenes/Game/scenes/FinalOk';
import FinalKo from './scenes/Game/scenes/FinalKo';
import Ranking from './scenes/Game/scenes/Ranking';

import './App.scss'



const App = (props) => {


  return (
    <Provider store={store}>
      <PersistGate
        loading={<h1>Loading...</h1>}
        //onBeforeLift={this.onBeforeLift}
        persistor={persistor}
      >
        <FeathersProvider>
          {/*<MemoryRouter>*/}
          <BrowserRouter>
            <Routes>
              <Route element={<MainLayout />}>
                <Route path="/login" element={<Login />} />
                <Route path="/proximamente" element={<ComingSoon />} />
              </Route>
              <Route element={<RequireAuth><MainLayout withGameContext noCounter /></RequireAuth>}>
                {/* <Route path="/" element={<Welcome />} /> */}
                <Route path="/:gameId/welcome/*" element={<Welcome />} />
              </Route>
              <Route element={<RequireAuth><DenseLayout withGameContext /></RequireAuth>}>
                <Route path="/" element={<GameChoice />} />
              </Route>
              <Route path="/:gameId/game/" element={<RequireAuth />}>
                <Route path="case/:caseId/*" element={<Case />} />
                <Route element={<GameLayout noCounter />}>
                  <Route path="finalok" element={<FinalOk />} />
                  <Route path="finalko" element={<FinalKo />} />
                </Route>
              </Route>
              <Route element={<GameLayout noCounter />}>
                {/* <Route path="/" element={<Welcome />} /> */}
                <Route path="/:gameId/ranking" element={<Ranking />} />
              </Route>
            </Routes>
          {/*</MemoryRouter>*/}
          </BrowserRouter>
        </FeathersProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
