import React, { useState, useEffect, useRef } from 'react';

import { useNavigate } from  'react-router-dom';
import { useCountdown } from './useCountdown';

const Timer = (props) => {

  const [countDown, setCountDown] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {

    if (props.targetDate) {
      const countDownDate = new Date(props.targetDate).getTime();

      setCountDown(countDownDate - new Date().getTime())

      const interval = setInterval(() => {
        if (countDownDate - new Date().getTime() > 0) setCountDown(countDownDate - new Date().getTime());
        else {
          setCountDown(0);
          clearInterval(interval);
          alert("El tiempo se ha agotado");
          navigate('/');
        }
      }, 1000);

      return () => {
        if (interval) clearInterval(interval)
      };
    } else {
      if (props.finalTime) {
        setCountDown(props.finalTime)
      } else {
        setCountDown(props.default * 1000)
      }

    }

  }, [props.targetDate, props.default]);

  const remainingSeconds = Math.floor(countDown / 1000);
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;

  return (
    <span className={`countdown font-mono text-lg ${remainingSeconds > 59 ? 'text-white' : 'text-red-500'}`}>
      <span style={{ "--value": minutes }}></span>:
      <span style={{ "--value": seconds }}></span>
    </span>
  );
}

export default Timer;