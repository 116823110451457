import React, { useEffect, useRef, useState, createContext, useContext } from 'react';

import feathers from '@feathersjs/feathers';
import socketio from '@feathersjs/socketio-client';
import io from 'socket.io-client';
import auth from '@feathersjs/authentication-client';

import { useDispatch } from 'react-redux';
import { update as updateApp } from './services/app/actions';

import manifest from './manifest.json';

const FeathersContext = createContext(null);
const events = require('events');

export {FeathersContext};

export const useFeathers = () => useContext(FeathersContext);

const FeathersProvider = ({children}) => {

  const socket = useRef(null);
  const client = useRef(null);
  const pendingGameplayLoaded = useRef(false);

  const [feathersObject, setFeathersObject] = useState(null);
  const [feathersReady, setFeathersReady] = useState(false);

  const dispatch = useDispatch();



  useEffect(() => {

    const socket = io(process.env.REACT_APP_API_URL);
    client.current = feathers();

    // Setup the transport (Rest, Socket, etc.) here
    client.current.configure(socketio(socket));

    // Available options are listed in the "Options" section
    client.current.configure(auth({
      storage: window.localStorage,
      storageKey: `drgaucher_jwt`,
    }));

    const find = (serviceName, params = {}) =>
      client.current.service(serviceName).find(params);
    const get = (serviceName, id, params = {}) =>
      client.current.service(serviceName).get(id, params);
    const create = (serviceName, data, params = {}) =>
      client.current.service(serviceName).create(data, params);
    const update = (serviceName, id, data, params = {}) =>
      client.current.service(serviceName).update(id, data, params);
    const patch = (serviceName, id, data, params = {}) =>
      client.current.service(serviceName).patch(id, data, params);
    const remove = (serviceName, id, params = {}) =>
      client.current.service(serviceName).remove(id, params);

    const authenticate = (accessToken) => {
      return client.current
        .authenticate({
          strategy: 'jwt',
          accessToken
        })
        .then((r) => {
          dispatch(
            updateApp({feathersAuthenticated: true, feathersUser: r.user}),
          );
        })
        .catch((e) => {
          dispatch(updateApp({feathersAuthenticated: false, feathersUser: {}}));
          throw e;
        });
    };

    const logOut = () => {
      return client.current.logout().then((r) => {
        dispatch(updateApp({feathersUser: {}, feathersAuthenticated: false}));
      });
    };

    const em = new events.EventEmitter();

    const initialSeq = async () => {
      try {
        dispatch(updateApp({manifest}));
        // First try to log in with an existing JWT
        const reauth = await client.current.reAuthenticate(true);
        dispatch(updateApp({feathersAuthenticated: true}));
        const response = await client.current.get('authentication');
        dispatch(updateApp({feathersUser: response.user}));

        // TODO Get my game only
        const currentGames = await client.current.service("gameplays").find({
          query: {
            userId: response.user._id,
            endTime: { $ne: null },
            $sort: { createdAt: -1 },
            $limit: 1
          }
        });
        if (currentGames.data.length > 0) {
          if (new Date(currentGames.data[0].endTime) > new Date()) { // if it's not finished
            em.emit('gameplayLoaded', currentGames.data[0]._id);
            pendingGameplayLoaded.current = currentGames.data[0]._id;
          } else {

          }
        }

        // I load active gameplay
        // const rides = await find('rides', {
        //   query: {
        //     $or: [
        //       {firstStopUser: response.user._id},
        //       {secondStopUser: response.user._id},
        //     ],
        //     $and: [{status: {$ne: 'Cancelled'}}, {status: {$ne: 'Arrived'}}],
        //   },
        //   paginate: false,
        // });
        // if (rides.total > 0) {
        //   dispatch(updateApp({ride: rides.data[0]}));
        // } else {
        //   dispatch(updateApp({ride: null}));

        //   const riders = await find('riders', {
        //     query: {
        //       user: response.user._id,
        //       looking: true,
        //     },
        //     paginate: false,
        //   });

        //   if (riders.total > 0) {
        //     dispatch(updateApp({rider: riders.data[0]}));
        //   } else {
        //     dispatch(updateApp({rider: null}));
        //   }
        // }
        setFeathersReady(true);
      } catch (error) {
        console.log("AUTH ERROR", error)
        dispatch(updateApp({manifest}));
        dispatch(updateApp({feathersAuthenticated: false, feathersUser: {}}));
        setFeathersReady(true);
      }
    };

    socket.on('connect', (connection) => {
      console.log('SOCKET CONNECTED');
      dispatch(updateApp({feathersConnected: true}));

      initialSeq();
    });

    socket.on('disconnect', (connection) => {
      console.log('SOCKET DISCONNECTED');
      dispatch(updateApp({feathersConnected: false}));
      setFeathersReady(false);
    });

    setFeathersObject({
      em,
      pendingGameplayLoaded,
      socket,
      client,
      authenticate,
      logOut,
      find,
      get,
      create,
      update,
      patch,
      remove,
    });

  }, []);

  return (
    <FeathersContext.Provider value={feathersObject}>
      {feathersReady ? children : <h1>Not connected</h1>}
    </FeathersContext.Provider>
  );
}

export default FeathersProvider;