import React, { useEffect } from "react";

import Button from '../../../../../../../components/Button';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../../../../../../services/app/actions';

import { useNavigate } from  'react-router-dom';

const Intro = props => {

  const navigate = useNavigate();

  const { clinicalCase, setLoading, block } = props;

  const { intro } = block;

  useEffect(() => {
    setLoading(true);
  }, []);

  return (
    <React.Fragment>
      <iframe
        title="Intro"
        className='body grow m-12 mb-8'
        src={intro}
        onLoad={ () => setLoading(false) }
      />
      <div className='footer flex shrink-0 justify-center'>
        <Button
          className='btn-secondary -translate-y-5'
          onClick={() => navigate(`question/${block.questions[0].id}`)}
        >Iniciar prueba</Button>
        <Button
          className='btn-secondary -translate-y-5 mx-4'
          onClick={() => {
            props.actions.app.update({ modal: { visible: true, source: clinicalCase.iframe } })
          }}
        >Supuesto clínico</Button>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    modal: state.services.app.modal
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Intro);