import React, { useEffect } from "react";

import Button from '../../../../../../../components/Button';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActionCreators from '../../../../../../../services/app/actions';

import { useNavigate, useParams } from  'react-router-dom';

const Outro = props => {

  const navigate = useNavigate();

  const { gameId } = useParams();

  const { setLoading, clinicalCase, block, blockIndex } = props;

  const { outro } = block;

  useEffect(() => {
    setLoading(true);
  }, []);

  let next = '/';

  let music = null;

  const game = props.manifest.games.find(g => g.id == gameId);
  const caseIndex = game.cases.findIndex(cc => cc.id == clinicalCase.id);

  //alert(`There are ${clinicalCase.blocks.length} blocks`);
  //alert(`blockIndex index is ${blockIndex}`);

  if (clinicalCase.blocks.length > blockIndex + 1) { // More blocks in this case
    next = `/${gameId}/game/case/${clinicalCase.id}/block/${clinicalCase.blocks[blockIndex + 1].id}`;
    music = clinicalCase.blocks[blockIndex + 1].music;
  } else if (game.cases.length > caseIndex + 1) { // No more blocks but another case
    //alert("ei")
    next = `/${gameId}/game/case/${game.cases[caseIndex + 1].id}/`;
  } else { // No more blocks nor cases
    next = `/${gameId}/game/final`;
  }

  return (
    <React.Fragment>
      <iframe
        title="Intro"
        className='body grow m-12 mb-8'
        src={outro}
        onLoad={ () => setLoading(false) }
      />
      <div className='footer flex shrink-0 justify-center'>
        <Button
          music={music}
          className='btn-secondary -translate-y-5'
          onClick={() => navigate(next)}
        >Siguiente</Button>
      </div>
    </React.Fragment>
  );
};

export default connect(
  (state, ownProps) => ({
    feathersUser: state.services.app.feathersUser,
    manifest: state.services.app.manifest,
    currentGame: state.services.app.currentGame,
  }),
  dispatch => ({
    actions: {
      app: bindActionCreators(appActionCreators, dispatch)
    }
  })
)(Outro);