import React, { useState } from 'react';


const Footer = props => {

  const [showTerms, setShowTerms] = useState(false);

  return (
    <React.Fragment>
      {showTerms && <div className='z-[99] absolute top-0 left-0 right-0 bottom-0 ' onClick={() => setShowTerms(false)}></div> }
      <div className='bg-white h-10 xl:px-4 flex items-stretch justify-between relative'>
        <div className='flex justify-center pt-2 pb-2'>
          <img src="/takeda.svg" alt="Takeda" className='h-full' />
        </div>
        <div className='flex flex-row py-2 ml-2'>
          <a target="_blank" className='border-r leading-6 px-2 text-[0.7rem] text-gray-400' href='https://streaklinks.com/BZS10VZdXcVABpTuAQ6ZFp7R/https%3A%2F%2Fenfermedadeslisosomales.com%2Fpolitica-de-privacidad'>Política de privacidad</a>
          <a target="_blank" className='border-r leading-6 px-2 text-[0.7rem] text-gray-400' href='https://streaklinks.com/BZS10UoMTHmVxUcZkwiyLsLN/https%3A%2F%2Fenfermedadeslisosomales.com%2Fcondiciones-legales-prof'>Condiciones legales</a>
          <a target="_blank" className='leading-6 px-2 text-[0.7rem] text-gray-400' href='https://streaklinks.com/BZS10VVyMsMaCG-nhQGqT5hq/https%3A%2F%2Fshire-promomats-international.veevavault.com%2Fui%2Fapproved_viewer%3Ftoken%3D13079-54ebf039-d3c6-4039-83eb-d825085c53ce%26chromeless%3Dtrue'>Ficha técnica Vpriv <sup>&reg;</sup></a>
        </div>

        <div className='flex flex-row py-2'>
          <p className='border-r leading-6 px-2 text-[0.7rem] text-gray-400'>+34 91 790 42 22</p>
          <p className='border-r leading-6 px-2 text-[0.7rem] text-gray-400 whitespace-nowrap'>Torre Europa - Paseo de la Castellana, 95, Planta 22 - 28046 Madrid</p>
          <p className='border-r leading-6 px-2 text-[0.7rem] text-gray-400'>Sobre Takeda: <a target="_blank" href='https://www.takeda.com/'>www.takeda.com</a></p>
          <p className='leading-6 px-2 text-[0.7rem] text-gray-400'>C-APROM/ES/VPR/0175 | Noviembre 2023</p>
        </div>

        <div className='pl-2 flex flex-row justify-end py-2'>
          {/* <p className='text-rid text-gray-400'>Copyright © 2022 Takeda Pharmaceutical Company Limited. All rights reserved. Takeda and the Takeda Logo are trademarks of Takeda Pharmaceutical Company Limited, used under license
          <br />Información de contacto para la recogida de reacciones adversas:
          <br />Tel.: + 34 91 790 42 22 (preguntar por el departamento de fármaco vigilancia)
          <br />Para más información visite nuestra web sobre farmacovigilancia
          <br />AE.ESP@Takeda.com
          <br />Spain@takeda.com
        </p> */}
          <span className='leading-6 px-2 text-[0.7rem] text-gray-400 underline cursor-pointer' onClick={() => setShowTerms(true)}>Copyright</span>
        </div>
        {showTerms &&
          <div className='absolute bottom-full right-6 z-[100]'>
            {/* <div className='absolute bottom-0 right-6 w-0 h-0 backdrop-blur-lg
            border-l-[20px] border-l-transparent
            border-t-[40px] border-t-white/20
            border-r-[20px] border-r-transparent
          '>s</div> */}
            <div className='w-60 mb-4 rounded-[25px] overflow-hidden'>
              <div className={`p-6 backdrop-blur-lg ${props.blackOverlay ? 'bg-black/20' : 'bg-white/20'} `}>
                <p className='text-white text-sm'>Copyright © 2024 Takeda Pharmaceutical Company Limited. All rights reserved.</p>
                <p className='text-white text-sm mt-2'>Takeda and the Takeda Logo are trademarks of Takeda Pharmaceutical Company Limited.</p>
                <p className='text-white text-sm mt-2'>Información de contacto para la recogida de reacciones adversas:</p>
                <p className='text-white text-sm mt-2'>Tel.: + 34 91 790 42 22 (preguntar por el departamento de fármaco vigilancia)</p>
                <p className='text-white text-sm mt-2'>Para más información visite nuestra web sobre farmacovigilancia</p>
                <p className='text-white text-sm mt-2'><a className='underline' href='mailto:AE.ESP@Takeda.com'>AE.ESP@Takeda.com</a>
                  <br /><a className='underline' href='mailto:Spain@takeda.com'>Spain@takeda.com</a>
                </p>
              </div>
            </div>
          </div>
        }

      </div>
    </React.Fragment>

  );
};

export default Footer;