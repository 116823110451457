import React from "react";

import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet
} from "react-router-dom";

import { connect } from "react-redux";
import { useFeathers } from "./FeathersProvider";



const RequireAuth = ({ isAuthenticated, children, ...props}) => {
  let location = useLocation();

  const feathers = useFeathers();

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <React.Fragment>
      {children}
      <Outlet />
      <div onClick={() => {
        feathers.logOut().then(() => {
          return <Navigate to="/login" state={{ from: location }} replace />;
        });
      }} style={{ display: 'block', position: 'absolute', top: 0, right: 0, width: 20, height: 20, cursor: 'pointer', zIndex: 9999 }}></div>
    </React.Fragment>
  );
}

export default connect(
  (state, ownProps) => ({
    isAuthenticated: state.services.app.feathersAuthenticated
  }), null
)(RequireAuth);
