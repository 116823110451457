import React, { useRef } from 'react';

import {
  Link
} from "react-router-dom";

const Button = (props) => {

  const audio = useRef(new Audio("/click.mp3"));

  const triggerBlockMusic = () => {
    if (props.stopMusic) {
      window.music.pause();
    } else if (props.music && window.music.getAttribute("src") != props.music) {
      window.music.setAttribute('src', props.music); //change the source
      window.music.load(); //load the new source
      window.music.play(); //play
    }
  }


  if (props.to) {
    return (
      <Link
        {...props}
        onClick={() => {
          //window.music.play();
          triggerBlockMusic();
          audio.current.play();
          if (props.onClick) props.onClick();
        }}
      >{props.children}</Link>
    );
  } else {
    return (
      <button
        {...props}
        onClick={() => {
          //window.music.play();
          triggerBlockMusic();
          audio.current.play();
          if (props.onClick) props.onClick();
        }}
      >{props.children}</button>
    );
  }

};

export default Button;